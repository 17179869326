<template>
  <!-- 底部缩略图区域 -->
  <div class="bottom_preview_bar" @click.stop>
    <div class="wrapper_tray">
      <img
        v-for="(item, index) in thumbList"
        :key="index"
        :src="item.url"
        alt=""
        class="img_item"
        :class="{ active: currentFocusIndex === index }"
        @click="imgItemClick({item, index})"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentFocusIndex: {
      type: Number,
      default: 0,
    },
    // 缩略图数据
    bottomBarData:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return {
      // 缩略图列表
      thumbList:[]
    }
  },
  created(){
  this.thumbList= this.bottomBarData.map(item=>{
      return {
        url: this.generatePath(item.id),
        ...item
      }
    })
  },
  methods: {
    // 缩略图item点击
    imgItemClick({item, index}) {
      this.$emit("imgItemClick", {item, index});
    },

    // 生成预览缩略图地址
    generatePath(id) {
      return `${
        this.$baseConfig.baseURL
      }/file/viewImg?id=${id}&accessToken=${this.$CCDK.CCToken.getToken()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
// 缩略图预览选中样式
.active {
  border: 3px solid rgb(18, 14, 235) !important;
}
// 底部缩略图预览区域 【高度70px】
.bottom_preview_bar {
 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  .wrapper_tray {
    background-color: #292c36;
    display: flex;
    align-items: center;
    .img_item {
      width: 50px;
      height: 50px;
      margin: 0 1px;
      border: 3px solid transparent;
      cursor: pointer;
    }
  }
}
</style>